import React, { useState } from 'react';
import './GroceryList.css';

function GroceryList() {
    const [items, setItems] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(null);

    const handleAddItem = () => {
        if (inputValue.trim()) {
            setItems([...items, inputValue.trim()]);
            setInputValue('');
        }
    };

    const handleDeleteItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };

    const handleEditItem = (index) => {
        setInputValue(items[index]);
        setIsEditing(true);
        setEditIndex(index);
    };

    const handleUpdateItem = () => {
        const newItems = [...items];
        newItems[editIndex] = inputValue;
        setItems(newItems);
        setInputValue('');
        setIsEditing(false);
        setEditIndex(null);
    };

    return (
        <div className="grocery-list">
            <h1>Grocery List</h1>
            <input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Add item to this list"
            />
            {isEditing ? (
                <button onClick={handleUpdateItem}>Update</button>
            ) : (
                <button onClick={handleAddItem}>Add</button>
            )}
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        {item}
                        <button onClick={() => handleEditItem(index)}>Edit</button>
                        <button onClick={() => handleDeleteItem(index)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default GroceryList;